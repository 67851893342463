

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import './index.css'

type Props = {
    text: string
}

export default function ToolTip({ text }: Props) {
    return (
        <>
            <div className="tooltip"><FontAwesomeIcon icon={faQuestionCircle} />
                <span className="tooltiptext">{text.split("\n").map(str => <p>{str}</p>)}</span>
            </div>
        </>
    )
}