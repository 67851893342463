import React from "react"
import type { Payer } from "../../data/types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import "@fortawesome/fontawesome-svg-core/styles.css"
import "./index.css"

import {
    Column,
    Table as ReactTable,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    ColumnDef,
    flexRender,
    SortingState,
    FilterFns,
} from "@tanstack/react-table"

import {
    RankingInfo,
    rankItem,
    compareItems,
} from "@tanstack/match-sorter-utils"
import ToolTip from "./ToolTip"

declare module "@tanstack/table-core" {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
        included: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

const includedFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    if (!value || !value.length) return true
    const val = row.getValue(columnId)
    return value.includes(val)
}

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId]?.itemRank!,
            rowB.columnFiltersMeta[columnId]?.itemRank!
        )
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

type Props = {
    payers: Payer[]
}

export function Table({ payers }: Props) {
    const rerender = React.useReducer(() => ({}), {})[1]

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        []
    )
    const [globalFilter, setGlobalFilter] = React.useState("")
    const [sorting, setSorting] = React.useState<SortingState>([
        { id: "name", desc: false },
    ])

    const columns = React.useMemo<ColumnDef<Payer, any>[]>(
        () => [
            {
                header: "Payer Name",
                footer: (props) => props.column.id,
                accessorKey: "name",
                cell: (info) => (
                    <div className="cell name-cell text-cell">{info.getValue()}</div>
                ),
            },
            {
                header: (e) => {
                    return (
                        <>
                            Code <ToolTip text="Wave HDC's Unique Payer Code." />
                        </>
                    )
                },
                footer: (props) => props.column.id,
                accessorKey: "id",
                cell: (info) => <div className="cell num-cell">{info.getValue()}</div>,
            },
            {
                id: "status",
                header: (e) => {
                    return (
                        <>
                            Quality
                            <ToolTip
                                text="'Good' means the Payer is responding to the majority of responses.
                            'Warning' means the Payer is responding but a higher than usual timeout rate.
                            'Unresponsive' means the Payer is not responding most of the time."
                            />
                        </>
                    )
                },
                footer: (props) => props.column.id,
                accessorKey: "status",
                cell: (info) => {
                    const quality = info.getValue() || "good"
                    const term = quality == "bad" ? "unresponsive" : quality
                    const upper = term.slice(0, 1).toUpperCase() + term.slice(1, 20)
                    return (
                        <div className={`cell text-cell quality-${quality}`}>{upper}</div>
                    )
                },
                filterFn: "included",
            },
            // {
            //     header: e => {
            //         return (
            //             <>
            //                 Avg Time
            //                 <ToolTip text="Average time in seconds.  Green is generally quick, yellow is slower, red is slow." />
            //             </>)
            //     },
            //     footer: props => props.column.id,
            //     accessorKey: 'responseTime',
            //     cell: info => {
            //         const time = info.getValue() ? info.getValue() : ""
            //         const quality =
            //             time == "" || time < 3.5
            //                 ? "good"
            //                 : time < 7.0
            //                     ? "warning"
            //                     : "bad"
            //         return <div className={`cell num-cell quality-${quality}`}>{time}</div>
            //     }
            // },
            {
                header: "Enrollment Required",
                footer: (props) => props.column.id,
                accessorFn: (props) => (props.enrollmentRequired ? "Yes" : " "),
                accessorKey: "enrollmentRequired",
                cell: (info) => {
                    return <div className="cell text-cell">{info.getValue()}</div>
                },
                filterFn: "equals",
            },
        ],
        []
    )

    const [data, setData] = React.useState<Payer[]>(payers)

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
            included: includedFilter,
        },
        state: {
            columnFilters,
            globalFilter,
            sorting,
            pagination: {
                pageSize: 2000,
                pageIndex: 0,
            },
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    return (
        <div className="container">
            <div className="filters">
                <table cellPadding={0} cellSpacing={0} className="table tk-serenity">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    {header.column.getCanFilter() ? (
                                                        <span className="filter">
                                                            <Filter column={header.column} table={table} />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                </table>
            </div>
            <div className="h-2" />
            <table cellPadding={0} cellSpacing={0} className="table tk-serenity">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <span
                                                    {...{
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {{
                                                        asc: <FontAwesomeIcon icon={faArrowDown} />,
                                                        desc: <FontAwesomeIcon icon={faArrowUp} />,
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </span>
                                            </>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td
                                            key={cell.id}
                                            className={`${cell.column.id == "name" ? "name-cell" : "data-cell"
                                                }`}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

function toggle_includes(value: string, selected: string[]) {
    if (selected.includes(value)) {
        const index = selected.indexOf(value)
        selected.splice(index, 1)
        return selected
    }
    return [...selected, value]
}

function create_button(column: Column<any, unknown>, value: string) {
    const filter_values = (column.getFilterValue() || [
        "good",
        "bad",
        "warning",
    ]) as string[]
    return (
        <button
            className={`status-option quality-${value}`}
            onClick={(e) =>
                column.setFilterValue(toggle_includes(value, filter_values))
            }
        >
            {filter_values.length == 0 || filter_values.includes(value) ? (
                <span>&#x2714;</span>
            ) : (
                ""
            )}
        </button>
    )
}

function Filter({
    column,
    table,
}: {
    column: Column<any, unknown>
    table: ReactTable<any>
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === "number"
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    if (column.id == "status") {
        return (
            <div className="status-options">
                {create_button(column, "good")}
                {create_button(column, "bad")}
                {create_button(column, "warning")}
            </div>
        )
    }

    if (column.id == "enrollmentRequired") {
        const [enrollment_filter, setEnrollment_filter] = React.useState("")

        return (
            <>
                <input
                    type="radio"
                    name="enrollment"
                    checked={enrollment_filter === ""}
                    onChange={(e) => {
                        column.setFilterValue("")
                        setEnrollment_filter("")
                    }}
                />
                <label
                    onClick={(e) => {
                        column.setFilterValue("")
                        setEnrollment_filter("")
                    }}
                >
                    All
                </label>

                <input
                    type="radio"
                    name="enrollment"
                    checked={enrollment_filter === "Yes"}
                    onChange={(e) => {
                        column.setFilterValue("Yes")
                        setEnrollment_filter("Yes")
                    }}
                />
                <label
                    onClick={(e) => {
                        column.setFilterValue("Yes")
                        setEnrollment_filter("Yes")
                    }}
                >
                    Yes
                </label>

                <input
                    type="radio"
                    name="enrollment"
                    checked={enrollment_filter === " "}
                    onChange={(e) => {
                        column.setFilterValue(" ")
                        setEnrollment_filter(" ")
                    }}
                />
                <label
                    onClick={(e) => {
                        column.setFilterValue(" ")
                        setEnrollment_filter(" ")
                    }}
                >
                    No
                </label>
            </>
        )
    }

    return typeof firstValue === "number" ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? 0)}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? 90)}
                    value={(columnFilterValue as [number, number])?.[0] ?? 0}
                    onChange={(value) =>
                        column.setFilterValue((old: [number, number]) => [value, old?.[1]])
                    }
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
                        ? `(${column.getFacetedMinMaxValues()?.[0]})`
                        : 0
                        }`}
                    className="w-24 search"
                />
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? 0)}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? 90)}
                    value={(columnFilterValue as [number, number])?.[1] ?? 90}
                    onChange={(value) =>
                        column.setFilterValue((old: [number, number]) => [old?.[0], value])
                    }
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
                        ? `(${column.getFacetedMinMaxValues()?.[1]})`
                        : 0
                        }`}
                    className="w-24 search"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <>
            <datalist id={column.id + "list"}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? "") as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Search...`}
                className="w-36 search"
                list={column.id + "list"}
            />
            <div className="h-1" />
        </>
    )
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    )
}
